/** @format */

import React, { useContext } from 'react';

import { actions } from '../reducers/app';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import routes from '../routes';
import Whitelabel from '@mollybet/frontend-common/dist/lib/Whitelabel';
import { Button } from '../components/interface';

import { withRouter } from 'react-router-dom';
import { ReactComponent as AccountCircle } from '../media/xena-icons/account.svg';
import { ReactComponent as Settings } from '../media/xena-icons/settings.svg';
import { ReactComponent as Feedback } from '../media/xena-icons/feedback.svg';
import { ReactComponent as Home } from '../media/xena-icons/home.svg';
import { ReactComponent as Orders } from '../media/xena-icons/orders.svg';
import BarChart from '@material-ui/icons/BarChart';
import Add from '@material-ui/icons/Add';
import TrendingUp from '@material-ui/icons/TrendingUp';
import { XenaDiv } from './shared/Common';
import { SettingsContext } from '../components/shared/SettingsContext';
import styled from 'styled-components';
import AttachMoney from '@material-ui/icons/AttachMoney';

const SideMenuHandler = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .click-off-handler {
    height: 100%;
    width: 100%;
  }

  .side-menu-grid-container {
    height: 100%;
    display: grid;
    grid-template-columns: 300px;
    grid-template-rows: 60px 1fr;

    .side-menu-row1 {
      grid-column: 1/2;
      grid-row: 1/2;
      box-shadow: 6px 6px 16px #00000017;
      opacity: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #a4adde;
      opacity: 1;

      .side-menu-logo {
        padding-left: 10px;
      }
    }

    .side-menu-row2 {
      grid-column: 1/2;
      grid-row: 2/3;
      box-shadow: 6px 6px 16px #00000017;
      opacity: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .side-menu-option-with-icon {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 47px;
        text-align: left;
        font: Light 16px/19px Roboto;
        letter-spacing: 0;
        opacity: 1;
        padding-left: 20px;

        &.special-case {
          padding-left: 18px;
        }
      }

      .side-menu-option {
        display: flex;
        align-items: center;
        height: 47px;
        text-align: left;
        font: Light 16px/19px Roboto;
        letter-spacing: 0;
        opacity: 1;
        padding-left: 20px;
      }

      .side-menu-icon {
        padding-right: 10px;

        &.special-case {
          padding-right: 8px;
        }
      }

      .sub-section-end {
        padding-bottom: 20px;
      }

      .faq {
        font-weight: bold;
        color: #838dff;
      }

      .desktop {
        font-size: 16px;
        margin-bottom: -6px;
        text-decoration: none;
      }

      .alink {
        color: currentColor;
      }
    }
  }
`;

const AccButton = styled(Button)`
  height: 24px;
  font-size: ${(props) => props.theme.fontSize};
  padding: 2px 6px;
  width: 110px;

  &:first-child {
    border-radius: 4px 0 0 4px !important;
  }

  &:nth-child(2) {
    border-radius: 0 4px 4px 0 !important;
  }
`;

const SideMenu = (props) => {
  const context = useContext(SettingsContext);

  const redirectTo = (loc) => () => {
    props.actions.toggleXenaSideMenu();
    props.history.push(`/${loc}`);
    return;
  };

  const toggleXenaSideMenu = () => {
    props.actions.toggleXenaSideMenu();
  };

  const logout = () => {
    props.actions.toggleXenaSideMenu();
    if (props.isBetbarExpanded) {
      props.actions.toggleBetbarExpanded();
    }
    props.actions.logout({
      actions: props.actions,
    });
  };

  const requestToken = (url) => () => {
    props.actions.requestToken({
      actions: props.actions,
      url,
      lang: context.language,
    });
  };

  const toggleParlayMode = () => {
    if (context.parlayMode) {
      props.actions.closeAllParlays({
        actions: props.actions,
      });
    } else {
      props.actions.closeAllPlacers({
        actions: props.actions,
      });
      props.actions.changeMarketInFocus({
        market: 'today',
      });
    }

    props.actions.togglePlacementMode();
    props.actions.toggleXenaSideMenu();
  };

  return (
    <SideMenuHandler>
      <div className="side-menu-grid-container">
        <XenaDiv
          background={'xenaColorDarkest'}
          borderColor={'borderHighlight'}
          className="side-menu-row1"
          onClick={toggleXenaSideMenu}
        >
          <div className="menuAndSearch">
            <div className="side-menu-logo">
              <img
                style={{ height: '40px' }}
                alt="logo"
                src={`${routes.basename}${
                  Whitelabel.logos
                    ? Whitelabel.logos['wide'][context.theme === 'light' ? 'light' : 'dark']
                    : ''
                }`}
              />
            </div>
          </div>
        </XenaDiv>
        <XenaDiv background={'xenaColorDarkest'} className="side-menu-row2">
          <div className="side-menu-option-with-icon" onClick={redirectTo('')}>
            <Home className="side-menu-icon" />
            <FormattedMessage id="xena.sidemenu.home" defaultMessage="Home" />
          </div>
          <div className="side-menu-option-with-icon" onClick={redirectTo('history/orders#page=1')}>
            <Orders className="side-menu-icon" />
            <FormattedMessage id="xena.sidemenu.orders" defaultMessage="Orders" />
          </div>
          <div
            className="side-menu-option-with-icon"
            onClick={redirectTo('positions#pageSize=1000')}
          >
            <TrendingUp className="side-menu-icon" />
            <FormattedMessage
              id="xena.sidemenu.activePositions"
              defaultMessage="Active positions"
            />
          </div>
          <div
            className="side-menu-option-with-icon sub-section-end"
            onClick={redirectTo('dashboard')}
          >
            <BarChart className="side-menu-icon" />
            <FormattedMessage id="xena.sidemenu.dashboard" defaultMessage="Dashboard" />
          </div>
          {context.featureParlay && (
            <div className="side-menu-option-with-icon sub-section-end">
              <AccButton
                variant={props.parlayMode ? 'secondary' : 'primary'}
                onClick={toggleParlayMode}
              >
                <FormattedMessage
                  id="trade.main.groupHead.parlay.singleMode"
                  defaultMessage="Single Bet"
                />
              </AccButton>
              <AccButton
                variant={props.parlayMode ? 'primary' : 'secondary'}
                onClick={toggleParlayMode}
              >
                <FormattedMessage
                  id="trade.main.groupHead.parlay.accumulatorMode"
                  defaultMessage="Accumulator"
                />
              </AccButton>
            </div>
          )}
          <div className="side-menu-option-with-icon" onClick={redirectTo('account')}>
            <AccountCircle className="side-menu-icon" />
            <FormattedMessage
              id="xena.sidemenu.accountAndBanking"
              defaultMessage="Account & banking"
            />
          </div>
          {Whitelabel.externalUrls &&
            Whitelabel.externalUrls['deposit'] &&
            context.featureDepositButton && (
              <div
                className="side-menu-option-with-icon special-case"
                onClick={requestToken(Whitelabel.externalUrls['deposit'])}
                to=""
              >
                <AttachMoney className="side-menu-icon special-case" />
                <FormattedMessage id="xena.sidemenu.deposit" defaultMessage="Deposit/ Withdraw" />
              </div>
            )}
          <div className="side-menu-option-with-icon" onClick={redirectTo('settings')}>
            <Settings className="side-menu-icon" />
            <FormattedMessage id="xena.sidemenu.settings" defaultMessage="Settings" />
          </div>
          <div
            className="side-menu-option-with-icon sub-section-end"
            onClick={redirectTo('feedback')}
          >
            <Feedback className="side-menu-icon" />
            <FormattedMessage id="xena.sidemenu.feedback" defaultMessage="Feedback" />
          </div>
          <a className="side-menu-option desktop alink" href={Whitelabel.desktopPlatformLink}>
            <FormattedMessage id="xena.sidemenu.desktopView" defaultMessage="Desktop view" />
          </a>
          {(props.sudo || props.limitedAdmin || props.sudoer) && (
            <div className="side-menu-option desktop" onClick={redirectTo('sudo')}>
              <FormattedMessage id="xena.sidemenu.sudoUser" defaultMessage="Sudo User" />
            </div>
          )}
          <div className="side-menu-option" onClick={logout}>
            <FormattedMessage id="xena.sidemenu.logOut" defaultMessage="Log out" />
          </div>
        </XenaDiv>
      </div>
      <div className="click-off-handler" onClick={toggleXenaSideMenu} />
    </SideMenuHandler>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = (state) => ({
  sudoer: state.getIn(['base', 'profile', 'sudoer'], ''),
  limitedAdmin: state.getIn(['base', 'profile', 'config', 'limitedAdmin'], false),
  sudo: state.getIn(['base', 'profile', 'config', 'sudo'], false),
  parlayMode: state.getIn(['ui', 'parlayMode'], false),
  isBetbarExpanded: state.getIn(['ui', 'settings', 'trade', 'betbar', 'expanded'], false),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideMenu));
